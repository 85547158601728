import React from 'react';
import { Heading1, Heading2, Paragraph } from '../components/Typography';
import Section from '../components/Section';
import HeroSection from '../components/HeroSection';
import SEO from '../components/seo';

const StepChangeDebtTest = () => (
	<>
		<SEO
			title='StepChange Debt Test'
			description={`
            Debt test to check your finances & see if you need debt help | Use 60 second debt check to test if you’re in debt | StepChange Debt Charity – free UK debt help
                `}
		/>
		<HeroSection paddingBottom='70px' paddingTop='8px'>
			<Heading1 marginTop='120px'>
				StepChange 60-Second Debt Test
			</Heading1>
			<Paragraph maxWidth='800px'>
				Answer the questions below and we'll help you understand how
				close you are to having a debt problem and suggest what your
				next steps could be.
			</Paragraph>
		</HeroSection>
		<Section
			backgroundColor='white'
			paddingTop='100px'
			paddingBottom='215px'
		>
			<iframe
				src='https://embed.stepchange.org/SixtySecondDebtTestPartner/?referrer=https%3A%2F%2Fwww.jacobsenforcement.com%2Fmanaging-your-money%2Fstep-change'
				height='700'
				width='100%'
				frameBorder='0'
				scrolling='no'
			></iframe>
		</Section>
	</>
);

export default StepChangeDebtTest;
